.logoCont{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 20%;
}

.logo{
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.bottomText{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vh;
    font-family: cursive;
    color: rgb(0, 0, 0);
    text-shadow: white 0px 0px 10px;
    width: 100%;
    height: 10%;
    padding-top: 2%;
}

.moreBottomText{
    display: flex;
    justify-content: center;
    font-size: 4vh;
    font-family: cursive;
    color: rgb(0, 0, 0);
    text-shadow: white 0px 0px 10px;
    height: 7%;
}

.centerCarousel{
    position: relative;
    display: block;
    height: 50%;

}

.carouselContainer{
    display: flex;
    position: relative;
    width: 65%;
    overflow: hidden;
    border-radius: 25%;
    box-shadow: 5px 5px 5px 5px rgba(219, 174, 51, 0.5);
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}

.image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120%;
    height: 120%;
    object-fit: fill;
}

.enabled{
    transform: translateX(-100%);
    transition-duration: 2s;
}

.nextImage{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120%;
    height: 120%;
    object-fit: fill;
}

.thirdImage{
    display: flex;
    justify-content: center;
    width: 120%;
    height: 120%;
    object-fit: fill;
}

.infoSquare{
    position: absolute;
    border-right: 5px solid black;
    border-left: 5px solid black;
    width: 50%;
    height: 100vh;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(255, 255, 255, 0.781);
}
