.NavBarContainer{
    position: relative;
    display: grid;
    grid-template-columns: 76% 8% 8% 8%;
    grid-template-areas: "Profile Potential Current Logout";
    list-style-type: none;
    background-color: rgb(238, 227, 213);
}

.NavBarContainerHome{
    position: relative;
    display: grid;
    grid-template-columns: 7% 82% 6% 5%;
    grid-template-areas: "Home Space Login Signup";
    list-style-type: none;
    background-color: rgb(238, 227, 213);
}

.bobaIcon{
    position: relative;
    width: 15%;
    height: 90%;
    background-image: url("./bobaicon.png");
    background-size: contain;
}


.bobaIconProfile{
    width: 1.8vh;
    height: 1.8vh;
    background-image: url("./bobaicon.png");
    background-size: contain;
}

.navButton{
    grid-area: Home;
    display: flex;
    align-items: center;
    max-height: 2em;
}

.navButtonProfile{
    grid-area: Profile;
    display: flex;
    align-items: center;
    max-height: 2em;
}

.homeButton{
    font-size: .8vw;
    text-decoration: none;
    color: rgb(175, 121, 50);
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding-top: 3%;
}

.profileButton{
    font-size: .8vw;
    text-decoration: none;
    color: rgb(175, 121, 50);
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.navBobaes{
    grid-area: Potential;
    display: flex;
    align-items: center;
    max-height: 100%;
    font-size: .8vw;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.navMatches{
    grid-area: Current;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    font-size: .8vw;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.navLogout{
    grid-area: Logout;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.navLogin{
    grid-area: Login;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .8vw;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.navlogin-button{
    text-decoration: none;
    color: rgb(175, 121, 50);
}

.navSignup{
    grid-area: Signup;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .8vw;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.navsignup-button{
    text-decoration: none;
    color: rgb(175, 121, 50);
}

.bobaes-button{
    text-decoration: none;
    color: rgb(175, 121, 50);
}

.matches-button{
    text-decoration: none;
    color: rgb(175, 121, 50);
}
