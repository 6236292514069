.loginForm{
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    left: 50%;
    transform: translate(-50%, 0);
    width: 40rem;
    height: 15rem;
    margin-top: 10%;
    border: 1px solid rgb(0, 0, 0);
    background-color: burlywood;
    border-radius: 25px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.loginTitle{
    margin-top: 10%;
    display: flex;
    justify-content: center;
    font-size: 5rem;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.loginHolder{
    position: absolute;
    overflow: auto;
    width: 50%;
    height: 100vh;
    border-right: 5px solid black;
    border-left: 5px solid black;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(255, 255, 255, 0.781);
}

.login-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginButton{
    font-size: 22px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
    height: 40px;
    width: 100px;
    border-radius: 25px;
}

.loginButton:hover{
    cursor: pointer;
}

.new-user{
    display: flex;
    justify-content: center;
    height: 10%;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1vw;
}

.signup-link{
    color: black;
    font-weight: bold;
}

.demo-buttons{
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin: auto;
}

.demoButton{
    height: 5vh;
    width: 5vw;
    border-radius: 25px;
    border: 3px solid black;
    background-color: rgba(192, 160, 55, 0);
    font-size: .6vw;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.demoButton:hover{
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.empty-space{
    height: 10%;
}
