.ImageContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20em;
    width: 20em;
    z-index: 150;
}

.prefImage{
    width: 15em;
    height: 15em;
    border-radius: 50%;
    border: 3px solid black;
    overflow: hidden;
    z-index: 200;
}

.prefImage:hover{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border-color: rgb(13, 184, 190);
    box-shadow:8px 8px 58px 11px rgba(34, 180, 190, 0.39);
    overflow: hidden;
    z-index: 200;
}

.prefimg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prefText{
    font-size: 40px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
