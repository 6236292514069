body, html {
    height: 100%;
    margin: 0;
    overflow: hidden;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.backgrounds{
    position: absolute;
    /* https://images.pexels.com/photos/5379707/pexels-photo-5379707.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260 */
    background-image: url("./bobaebackground.png");
    width: 100%;
    height: 100%;
    background-size: cover;
    filter: blur(5px);
}

.loader{
    width: 100%;
    height: 100%;
}

.dev-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2%;
    position: fixed;
    background-color: rgb(238, 227, 213);
    bottom: 0;
}

.developedBy{
    font-size: .8vw;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
}

.link{
    margin-left: .7%;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.git-icon{
    height: .8vw;
    text-decoration: none;
    color: black;
}

.dev-name{
    /* text-decoration: none; */
    color: black;
}
