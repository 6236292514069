.matchImageCont{
    width: 40%;
    height: 85%;
    overflow: hidden;
    border: 1px solid black;
    border-radius: 50%;
}

.profImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.matchRectangle{
    display: grid;
    grid-template-areas:
    "User Interest Extra"
    "User Interest Button";
    grid-template-columns: 50% 25% 25%;
    grid-template-rows: 90% 10%;
    border-bottom: 3px solid black;
    height: 35%;
}

.userDetails{
    grid-area: User;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    max-height: 100%;
    border-right:3px solid black;
}

.matchUserInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-left: 10%;
    font-size: 1.3vw;
    height: 100%;
    width: 30%;
    word-break: break-all;
}

.userPreferences{
    grid-area: Interest;
    display: flex;
    font-size: 30px;
    width: 100%;
}

.bobaPrefTitle{
    font-size: 1.8vw;
    width: 100%;
    padding-left: 3%;
}

.matchPrefDetails{
    text-align: end;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 75%;
    font-size: 60%;
}

.matchHolder{
    width: 65%;
    border-right: 5px solid black;
    border-left: 5px solid black;
    background-color: rgba(255, 255, 255, 0.781);
    /* overflow-y: auto; */
    position: absolute;
    height: 100vh;
    left: 50%;
    transform: translate(-50%, 0);
}

.matchPrefExtraDetails{
    grid-area: Extra;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.3vw;
    padding-top: 3%;
    padding-left: 3%;
    margin-left: 5%;
    border-left: 1px dotted black;
}

.yourBobaesTitle{
    display: flex;
    justify-content: center;
    font-size: 80px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
    padding-bottom: 2%;
    border-bottom: 3px solid black;
    padding-top: 2%;
}

.DeleteArea{
    grid-area: Button;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    margin-left: 5%;
    border-left: 1px dotted black;
}

.DeleteButton{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: .8vw;
    background-color: rgba(235, 169, 63, 0.657);
    color: rgb(0, 0, 0);
    font-weight: 800;
}


.DeleteButton:hover{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: .8vw;
    background-color: rgba(218, 55, 14, 0.562);
    color: rgb(0, 0, 0);
    font-weight: 800;
    cursor: pointer;
}

.profilesBox{
    overflow-y: scroll;
    height: 80%;
}

.tooltipMatch{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 75px;
    margin-top: 2%;
    margin-left: 85%;
    background-color: rgba(0, 255, 255, 0);
    border-radius: 50%;
    text-align: center;
    border: none;
}

.tooltipMatch:hover{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    height: 75px;
    width: 75px;
    margin-left: 85%;
    border-radius: 50%;
    background-color: rgba(226, 12, 48, 0.733);
    box-shadow:3px 3px 40px 9px rgba(226, 12, 48, 0.733);
}

.space{
    height:100px;
}

.deleteMatchExample{
    grid-area: buttonIcon;
    text-align: center;
    margin: auto;
    height: 12%;
    width: 40%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: .7vw;
    background-color: rgba(218, 55, 14, 0.562);
    color: rgb(0, 0, 0);
    font-weight: 800;
    border: 1px solid black;
}

.toolModalMatch{
    position: absolute;
    top: 20%;
    left: 30%;
    right: 30%;
    bottom: 35%;
    background-color: white;
    border-radius: 25px;
}
