.HeightBox{
    display: flex;
}

.signUpForm{
    position: relative;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    width: 30rem;
    height: 55rem;
    left: 50%;
    transform: translate(-50%, 0);
    border: 1px solid rgb(0, 0, 0);
    background-color: burlywood;
    border-radius: 25px;
    margin: 2em 2em 0 0;
}

.inputLabel{
    font-size: 1em;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
}

.inputArea{
    margin-top: 8px;
    height: 1em;
    width: 15em;
    border-radius: 25px;
    font-size: 1.2em;
}

.uploadArea{
    margin-top: 8px;
    height: 1.5em;
    width: 15em;
    font-size: 1.2em;
}

.radio{
    transform: scale(1.5);
    margin-left: 5%;
}

.radioLabels{
    font-size: 15px;
}

.genderLabel{
    margin-right: 50%;
    font-size: 1em;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
}

.heightLabel{
    margin-right: 50%;
    font-size: 1em;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
}

.heightInput{
    margin-top: 8px;
    height: 1em;
    width: 6em;
    z-index: 60;
    border-radius: 25px;
    font-size: 1em;
}

.heightDetails{
    font-size: 15px;
}

.feetDiv{
    margin-left: 10px;
}

.SignUpTitle{
    margin-top: 3%;
    display: flex;
    justify-content: center;
    font-size: 5rem;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.signUpButton{
    font-size: 1em;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
    margin-top: 1%;
    margin-bottom: 5%;
    height: 40px;
    width: 100px;
    border-radius: 25px;
}

.signUpButton:hover{
    cursor: pointer;
}

.signupHolder{
    position: absolute;
    overflow: auto;
    width: 50%;
    height: 100%;
    border-right: 5px solid black;
    border-left: 5px solid black;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(255, 255, 255, 0.781);
}

.errorsDiv{
    max-height: 100%;
    color: red;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
