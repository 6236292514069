.chat-container.disabled{
    display: none;
}

.chat-button{
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 10%;
    -webkit-animation: breathing 2s ease-out infinite normal; /*codepen animation from https://codepen.io/machi/pen/YymGzP*/
    animation: breathing 2s ease-out infinite normal; /*codepen animation from https://codepen.io/machi/pen/YymGzP*/
    height: 8%;
    bottom: 5%;
    right: 3%;
    font-size: .9vw;
    border-radius: 25px;
    font-weight: 800;
    background-color: blanchedalmond;
}

.close-button{
    position: absolute;
    color: red;
    bottom:35.2%;
    right: 1%;
    font-size: 1vw;
    font-weight: bolder;
    border: 3px solid rgb(0, 0, 0);
    background-color: bisque;
    text-align: end;
}

.close-button.disabled{
    display: none;
}

.close-button:hover{
    cursor: pointer;
}

.chat-button.disabled{
    display: none;
}

.chat-button:hover{
    cursor: pointer;
}

.chat-container{
    position: absolute;
    width: 15%;
    height: 30%;
    bottom: 5%;
    right: 1%;
    border: 3px solid rgb(0, 0, 0);
    display: grid;
    grid-template-areas: "users chat";
    grid-template-columns: 25% 75%;
}

.names-chat-container{
    grid-area: users;
    background-color: rgb(195, 130, 87);
    overflow-y: auto;
    border-right: 1px solid black;
}

.chat-area{
    grid-area: chat;
    display: grid;
    grid-template-areas:
    "title"
    "view"
    "input";
    grid-template-rows: 10% 80% 10%;
    background-color: bisque;
    overflow: hidden;
}

.user-chat-container{
    border-bottom: 1px solid black;
    font-size: .7vw;
    word-wrap: break-word;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.user-chat-container:hover{
    background-color: blueviolet;
    cursor: pointer;
}

.chat-view{
    grid-area: view;
    overflow-y: scroll;
    font-size: .8vw;
    display: flex;
    flex-direction: column;
}

.chat-text{
    word-wrap: break-word;
}

.chat-input{
    grid-area: input;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.input-element{
    width: 60%;
    height: 60%;
}

.chat-submit{
    width: 20%;
    height: 80%;
    font-size: .5vw;
}

.chat-title{
    text-align: center;
    font-size: .7vw;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: brown;
    overflow: auto;
}

.pick-input{
    grid-area: chat;
    text-align: center;
    margin: auto;
    font-size: 2vw;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    height: 100%;
    background-color: bisque;
}

.chat-space{
    font-size: .7vw;
}

.chat-submit{
    font-size: .5vw;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
    background-color: rgb(238, 177, 96);
}

.chat-submit:hover{
    cursor: pointer;
}

@-webkit-keyframes breathing { /*codepen animation from https://codepen.io/machi/pen/YymGzP*/
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    60% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes breathing {
    0% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }

    25% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    60% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }

    100% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }
}
