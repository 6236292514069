.HeightBox{
    display: flex;
}

.editProfileForm{
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    width: 30em;
    height: 40em;
    border: 1px solid rgb(0, 0, 0);
    margin-top: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: burlywood;
    border-radius: 25px;
}

.submitChangesButton{
    font-size: 1em;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
    margin-top: 5%;
    margin-bottom: 8%;
    height: 50px;
    width: 140px;
    border-radius: 25px;
}

.submitChangesButton:hover{
    cursor: pointer;
}

.editHolder{
    position: absolute;
    overflow-y: scroll;
    width: 50%;
    height: 100vh;
    border-right: 5px solid black;
    border-left: 5px solid black;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(255, 255, 255, 0.781);
}
