.logoutButton{
    background-color: rgba(250, 235, 215, 0);
    border: none;
    text-decoration: none;
    font-size: .8vw;
    color: rgb(175, 121, 50);
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.logoutButton:hover{
    cursor: pointer;
}
