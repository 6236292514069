.profileDetailsCont{
    position: absolute;
    display: grid;
    grid-template-areas:
    "info preferences";
    grid-template-columns: 50% 50%;
    border: 3px solid black;
    height: 85%;
    width: 90%;
    margin-left: 6%;
    margin-top: 4%;
    background-color: rgba(253, 253, 253, 0.808);
    border-radius: 50px;
}

.profileImage{
    position: absolute;
    border: 5px solid black;
    border-radius: 50%;
    width: 15vw;
    height: 28vh;
    overflow: hidden;
    margin-top:.5%;
    margin-left: .5%;
}

.pImg{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.detailsTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    margin-left: 25%;
    margin-top: 4%;
    height:12%;
    max-width: 50%;
    font-weight: 800;
    border-radius: 25px;
    background-color: rgba(222, 184, 135, 0.411);
}

.preferenceTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    margin-left: 20%;
    height:12.5%;
    max-width: 50%;
    font-weight: 800;
    border-radius: 25px;
    background-color: rgba(222, 184, 135, 0.411);
}

.editInfo{
    position: relative;
    height: 7.5vh;
    width: 35%;
    border-radius: 25px;
    background-color: rgb(222, 184, 135);
    font-weight: bolder;
    font-size: 1.5vw;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.438);
}

.editInfo:hover{
    position: relative;
    cursor: pointer;
    height: 7.5vh;
    width: 35%;
    background-color: rgb(235, 148, 34);
    font-weight: bolder;
    font-size: 1.5vw;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.438);
}

.editPref{
    position: relative;
    height: 7.5vh;
    width: 40%;
    border-radius: 25px;
    background-color: rgb(222, 184, 135);
    font-weight: bolder;
    font-size: 1.5vw;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.438);
}

.editPref:hover{
    position: relative;
    cursor: pointer;
    height: 7.5vh;
    width: 40%;
    background-color: rgb(235, 148, 34);
    font-weight: bolder;
    font-size: 1.5vw;
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.438);
}

.leftContainer{
    grid-area: info;
    border-right: 2px dotted black;
    width: 100%;
    height: 100%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.rightContainer{
    grid-area: preferences;
    margin-top: 4%;
    margin-left: -5%;
    width: 96%;
    height: 96%;
    font-size: 160px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

.profileDetails{
    display: flex;
    flex-direction: column;
    margin-left: 30%;
    justify-content: space-evenly;
    height: 65%
}

.profileDetailsRight{
    display: flex;
    flex-direction: column;
    margin-left: 26%;
    justify-content: space-evenly;
    height: 65%
}

.userName{
    font-size: 2vw;

}

.name{
    font-size: 2vw;
}

.cityZip{
    font-size: 2vw;
}

.age{
    font-size: 2vw;
}

.height{
    font-size: 2vw;
}

.backgroundImageContProfile{
    position: absolute;
    margin-top: -.5%;
    width: 101%;
    height: 96%;
}

.backgroundImageProfile{
    position: absolute;
    z-index: -100;
    filter: blur(5px);
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.editInfoBox{
    display: flex;
    justify-content: center;
}

.editPrefBox{
    display: flex;
    justify-content: center;
    padding-left: 10%;
    padding-top: 3%;
}
