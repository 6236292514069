.TeaContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    height: 88%;
    margin-left: auto;
    margin-right: auto;
}


.TeaTitle{
    margin-top: 5vh;
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
    font-size: 3vw;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.almostTitle{
    margin-top: 20vh;
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
    /* text-align: center; */
    font-size: 3vw;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.behindChoice{
    z-index: 50;
}

.choice{
    z-index: 100;
}


.choiceHolder{
    overflow-y: scroll;
    position: absolute;
    width: 80%;
    border-right: 5px solid black;
    border-left: 5px solid black;
    height: 100vh;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(255, 255, 255, 0.781);
}

.choiceHolderDesc{
    position: absolute;
    overflow-y: scroll;
    border-right: 5px solid black;
    border-left: 5px solid black;
    width: 70%;
    height: 100vh;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(255, 255, 255, 0.781);
}


.choiceQuestion{
    margin-top: 3%;
    font-size: 2em;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
    color: rgb(212, 198, 178);
    text-align: center;
}

.typeBox{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 8%;
    width: 55%;
    height: 25rem;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(133, 85, 40, 0.904);
    border-radius: 25px;
    border: 3px solid black;
}

textarea {
        background-color: rgba(240, 209, 168, 0.842);
        border: 1px solid #888;
        height: 10rem;
        margin-top: 7%;
        width: 90%;
        margin-left: 5%;
        font-size: 20px;
        border-radius: 15px;
        resize: none;
    }

.submitButton{
    font-size: 1em;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 800;
    margin-top: 1%;
    margin-right: 2%;
    height: 40px;
    width: 100px;
    border-radius: 25px;
}
.submitContainer{
    display: flex;
    justify-content: flex-end;
}
