.matchProfiles{
    height: 100%;
    overflow-y: auto;
}

.profImageCont{
    width: 50%;
    height: 70%;
    overflow: hidden;
    border: 1px solid black;
    border-radius: 50%;
    margin-top: 6%;
    margin-left: 10%;
    margin-right: 10%;
}

.profImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profilesOuterContainer{
    margin-top: 2%;
    height: 90%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.profilesContainer{
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

}

.buttonsAndPic{
    display: flex;
    width: 100%;
    height: 30vh;
}

.acceptBut{
    width: 8vw;
    height: 8vh;
    border-radius: 20%;
    margin-top: 8%;
    margin-left: 20%;
    box-shadow: 8px 8px 2px 1px rgba(0, 0, 255, 0.226);
    overflow: hidden;
}

.acceptImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.acceptBut:hover{
    width: 8vw;
    height: 8vh;
    border-radius: 20%;
    margin-top: 8%;
    margin-left: 20%;
    box-shadow: 8px 8px 2px 1px rgba(0, 0, 255, 0.226);
    overflow: hidden;
    box-shadow:8px 8px 58px 11px rgba(6, 221, 71, 0.897);
}

.denyBut{
    width: 8vw;
    height: 8vh;
    border-radius: 20%;
    margin-top: 8%;
    margin-right: 20%;
    box-shadow: 8px 8px 2px 1px rgba(0, 0, 255, 0.226);
    overflow: hidden;
}

.denyBut:hover{
    width: 8vw;
    height: 8vh;
    border-radius: 20%;
    margin-top: 8%;
    margin-right: 20%;
    box-shadow:8px 8px 58px 11px rgba(226, 12, 48, 0.733);
}

.profileContents{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    width: 60%;
    border: 1px solid black;
    border-radius: 25px;
    background-color: bisque;
    margin-left: 19.5%;
    margin-top: 4%;
    margin-bottom: 18%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.about{
    display: flex;
    justify-content: center;
    font-size: 50px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-top: 4%;
    color: rgb(194, 128, 47);
}

.userNameTitle{
    margin-bottom: -1%;
    display: flex;
    justify-content: center;
    font-size: 40px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.nameTitle{
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.profileCity{
    font-size: 1.2vw;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.milkTeaDetails{
    margin-top: -1%;
}

.mtList{
    margin-top: -8%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 6em;
}

.iLove{
    font-size: 1.3vw;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.extraDets{
    font-size: 1.2vw;
}

.extraDets2{
    font-size: 1.2vw;
    margin-top: 3%;
    margin-bottom: 2%;
}

.finalDesc{
    font-size: 1.2vw;
    margin-top: 3%;
}

.bobaeTitle{
    margin-top: 2%;
    display: flex;
    justify-content: center;
    font-size: 80px;
    font-weight: 800;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.listDetails{
    font-size: 1.1vw;
}

.bobaeHolder{
    position: absolute;
    border-right: 5px solid black;
    border-left: 5px solid black;
    width: 50%;
    height: 100vh;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(255, 255, 255, 0.781);
}

.tooltip{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 75px;
    margin-top: 2%;
    margin-left: 80%;
    background-color: rgba(0, 255, 255, 0);
    border-radius: 50%;
    text-align: center;
    border: none;
}

.tooltip:hover{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    height: 75px;
    width: 75px;
    margin-left: 80%;
    border-radius: 50%;
    background-color: rgba(226, 12, 48, 0.733);
    box-shadow:3px 3px 40px 9px rgba(226, 12, 48, 0.733);
}

.toolModal{
    position: absolute;
    top: 20%;
    left: 30%;
    right: 30%;
    bottom: 25%;
    background-color: white;
    border-radius: 25px;
}

.toolOverlay{
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    background-color: rgba(0, 0, 0, 0.63);
}

.closeModal{
    margin-left: 93%;
    width: 5%;
    height: 10%;
    color: red;
    font-weight: 800;
    font-size: 1.5vw;
    background-color: rgba(250, 235, 215, 0);
    border: none;
}

.toolTitle{
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1.3vw;
    font-weight: 800;
}

.toolDescription{
    text-align: center;
    margin-top: 5%;
    font-size: .8vw;
}

.tipbox{
    display: grid;
    grid-template-areas: "buttonIcon message";
    grid-template-columns: 30% 60%;
    margin-top: 5%;
}

.toolAccept{
    grid-area: buttonIcon;
    width: 5vw;
    height: 8vh;
    border-radius: 20%;
    overflow: hidden;
    margin: auto;
    border: 1px solid black;
}

.tips{
    grid-area: message;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1vw;
    font-weight: 800;
}

.note{
    margin-top: 5%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 1vw;
    text-align: center;
}

.toolInfo{
    /* overflow-y: scroll; */
    height: 80%;
}

.end-matches{
    display: flex;
    justify-content: center;
    height: 50%;
    align-items: center;
    font-size: 3vw;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
